import { initializeApp } from "firebase/app";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseApp = initializeApp({
    apiKey: "AIzaSyC7Ffi078J_ewW6UEgUYTVRmrqAtwXH8os",
    authDomain: "morbid-1d8ea.firebaseapp.com",
    projectId: "morbid-1d8ea",
    storageBucket: "morbid-1d8ea.appspot.com",
    messagingSenderId: "361709904191",
    appId: "1:361709904191:web:ab9a8957a7635d9f67f38a",
    measurementId: "G-BMFZCVNCQF"
//   apiKey: process.env.FIREBASE_API_KEY,
//   authDomain: process.env.FIREBASE_AUTH_DOMAIN,
//   databaseURL: process.env.FIREBASE_DB_URL,
//   projectId: process.env.FIREBASE_PROJECT_ID,
//   storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
//   messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID,
//   appId: process.env.FIREBASE_APP_ID,
//   measurementId: process.env.FIREBASE_MEASUREMENT_ID,
});
