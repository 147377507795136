import axios from 'axios';

// Create Axios instances with different base URLs
export const apiAxios = axios.create({
    baseURL: "https://dashboard.vackao.com/api",
    // baseURL: "https://dashboard-test.supportletstalk.com/api",
    // baseURL: "http://localhost:8080/api",


    headers: {
        'Content-Type': 'application/json',
    },
});

export const firebaseAxios = axios.create({
    baseURL: 'https://firestore.googleapis.com/v1/projects/support-stress-free/databases/(default)/documents',
    headers: {
        'Content-Type': 'application/json',
    },
});

